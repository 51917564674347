<div class="tabs" [ngClass]="getClassName()">
  <ul [ngClass]="tabClass + '__list'">
    <li
      *ngFor="let tab of tabs; let i = index"
      [ngClass]="tabClass + '__item'"
      [class.active]="tab.active"
      (click)="selectTab(i)"
    >
      @if(tab.tabImage) {
        <img
          src="{{ tab.tabImage }}"
          class="team-image"
          alt="team crest"
        />
      }
      
      <span [ngClass]="tabClass + '__item__title'">{{ tab.tabTitle }}</span>
      <span *ngIf="tab.showBadge"></span>
      <spaui-badge
        *ngIf="tab.showBadge"
        [badgeType]="'pill'"
        [label]="tab.badgeText"
        [type]="'primary'"
        [ngStyle]="{'margin-left': (alignment==='vertical') ? 'auto' : '8px'}"
      ></spaui-badge>
    </li>
  </ul>
  <div [ngClass]="tabClass + '__content'">
    <ng-content></ng-content>
  </div>
</div>
