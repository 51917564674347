import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabGroupComponent } from "./components/tab-group/tab-group.component";
import { TabComponent } from "./components/tab/tab.component";
import { BadgeModule } from "../badge/badge.module";

@NgModule({
  declarations: [TabGroupComponent, TabComponent],
  imports: [CommonModule, BadgeModule],
  exports: [TabGroupComponent, TabComponent],
})
export class TabsModule {}
