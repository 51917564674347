import { Component, Input } from "@angular/core";

@Component({
  selector: "spaui-tab",
  templateUrl: "./tab.component.html",
  styleUrl: "./tab.component.scss"
})
export class TabComponent {
  @Input() tabTitle!: string;
  @Input() active = false;
  @Input() showBadge = false;
  @Input() badgeText: any;
  @Input() cssClass: string = '';
  @Input() tabImage: string = '';
}
