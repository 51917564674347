import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-video-analysis-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
  template: `
    <div class="container-fluid skeleton-container">
      <div class="row">
        <div class="col col-1">
          <ng-container *ngFor="let skeleton of skeletons; let i = index">
            <div class="text-center left-circle">
              <ngx-skeleton-loader 
                count="1" 
                appearance="circle" 
                [theme]="circleTheme">
              </ngx-skeleton-loader>
            </div>
          </ng-container>
        </div>
        
        <div class="col col-7 text-center">
          <ngx-skeleton-loader 
            count="1" 
            [theme]="mainLoaderTheme">
          </ngx-skeleton-loader>
        </div>
        
        <div class="col col-4 text-center">
          <ngx-skeleton-loader 
            *ngFor="let skeleton of lineSkeletons" 
            count="1" 
            [theme]="lineLoaderTheme">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .skeleton-container {
      margin: 16px -12px;
    }
    .left-circle {
      margin-left: 36px; /* Apply margin left to all circles */
    }
  `]
})
export class VideoAnalysisSkeletonComponent {
  skeletons = Array(3); // Adjust the number of circles
  lineSkeletons = Array(11); // Adjust the number of lines

  // Common theme for circle loaders
  circleTheme = {
    width: '50px',
    height: '50px',
    'border-radius': '5px',
    'background-color': '#D9D9D9'
  };

  // Common theme for main loader
  mainLoaderTheme = {
    'border-radius': '5px',
    height: '100%',
    'background-color': '#D9D9D9',
    'margin': '0px 24px',
    'width': 'calc(100% - 40px)'
  };

  // Common theme for line loaders
  lineLoaderTheme = {
    'border-radius': '5px',
    height: '50px',
    'background-color': '#D9D9D9',
    'width': 'calc(100% - 30px)'
  };
}