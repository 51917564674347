import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-fieldmap-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
  template: `
    <div class="container-fluid skeleton-container">

      <!-- Row 1:  -->
      <div class="row align-items-center mb-2">
        <div class="col-8">
          <ngx-skeleton-loader count="1" [theme]="lineTheme"></ngx-skeleton-loader>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="circleTheme"></ngx-skeleton-loader>
        </div>
      </div>

      <!-- Row 2: -->
      <div class="row align-items-center mb-2">
        <div class="col-8">
          <ngx-skeleton-loader count="1" [theme]="lineTheme"></ngx-skeleton-loader>
        </div>
        <div class="col-2">
          <ngx-skeleton-loader count="1" [theme]="smallLineTheme"></ngx-skeleton-loader>
        </div>
        <div class="col-2 ">
          <ngx-skeleton-loader count="1" [theme]="lineTheme"></ngx-skeleton-loader>
        </div>
      </div>

      <!-- Row 3:  -->
      <div class="row mt-15 mb-2">
        <div class="col-2">
          <ngx-skeleton-loader count="1" [theme]="lineTheme"></ngx-skeleton-loader>
        </div>
        <div class="col-4">
          <ngx-skeleton-loader count="1" [theme]="longLineTheme"></ngx-skeleton-loader>
        </div>
      </div>

      <!-- Row 4:  -->
      <div class="row mt-15 mb-2">
        <div class="col-10">
          <ngx-skeleton-loader count="1" [theme]="mediumLineTheme"></ngx-skeleton-loader>
        </div>
      </div>

      <!-- Row 5: -->
      <div class="row mt-3  mb-2">
        <div class="col-12">
          <ngx-skeleton-loader count="1" [theme]="largeBlockTheme"></ngx-skeleton-loader>
        </div>
      </div>

    </div>
  `,
  styles: [`
    .skeleton-container {
      padding: 20px;
      width:90%;
      overflow: hidden;
    }
    .justify-content-end {
      display: flex;
      justify-content: end;
    }
    .mt-15 {
      margin-top:15px
    }
  `]
})
export class FieldmapSkeletonComponent {
  // Small Circle (Row 1 Right Side)
  circleTheme = {
    width: '25px',
    height: '25px',
    'background-color': '#D9D9D9'
  };

  // Two Long Lines (Row 1)
  lineTheme = {
    height: '25px',
    width: '90%',
    'background-color': '#D9D9D9',
    'border-radius': '4px',
    margin: '5px 0'
  };

  // One Big Line (Row 2 Left)
  longLineTheme = {
    height: '25px',
    width: '80%',
    'background-color': '#D9D9D9',
    'border-radius': '4px',
    'margin': '5px 0',
  };

  // Small Lines (Row 2 Right & Row 3)
  smallLineTheme = {
    height: '25px',
    width: '30%',
    'background-color': '#D9D9D9',
    'border-radius': '4px',
    'margin': '5px 0px'
  };

  // Medium Line (Row 4)
  mediumLineTheme = {
    height: '25px',
    width: '60%',
    'background-color': '#D9D9D9',
    'border-radius': '4px'
  };

  // Large Content Block (Row 5)
  largeBlockTheme = {
    height: '200px',
    width: '100%',
    'background-color': '#D9D9D9',
    'border-radius': '6px'
  };
}