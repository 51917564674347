import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  numberAttribute,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'spaui-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrl: './tab-group.component.scss',
})
export class TabGroupComponent implements OnChanges {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Input({ transform: numberAttribute }) selectedIndex!: number | null;
  @Input() alignment: 'horizontal' | 'vertical' = 'horizontal';
  @Output() selectedIndexChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() isEnhancedStyle: boolean = false;
  tabClass: string = 'tabs'

  ngAfterContentInit() {
    this.tabClass = `tabs_${this.alignment}`;
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0 && !this.selectedIndex) {
      this.selectTab(0);
    } else {
      this.selectTab(this.selectedIndex!);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedIndex' in changes) {
      this.selectTab(this.selectedIndex!);
    }
  }

  selectTab(index: number) {
    if (this.tabs && this.tabs.length > 0) {
      this.tabs.toArray().forEach((tab) => (tab.active = false));
      const tab = this.tabs.get(index);
      if (tab) {
        this.selectedIndex = index;
        tab.active = true;
        this.selectedIndexChange.emit(index);
      }
    }
  }

  getClassName():string {
    let className = this.alignment;
    if (this.isEnhancedStyle) {
      className = className + ' '+ 'enhancedTab';
    }
    return className;
  }
}
