import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import oidcClient from 'oidc-client';
import { Observable, Subject } from 'rxjs';
import { UserProfile } from '../../types/Auth';
import { TranslateService } from '@ngx-translate/core';
import { clearStorageByPrefix } from 'src/app/shared/utils';

const { UserManager } = oidcClient;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly TOKEN_NAME = 'token';
  private userManager: any;
  private user: any = null;
  private logoutSubject = new Subject<void>();
  
  logout$ = this.logoutSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService
  ) {
    this.userManager = new UserManager(environment.idsConfig);
    
    this.userManager.events.addUserSignedOut(() => this.handleLogout());
    
    const broadcastChannel = new BroadcastChannel('auth_channel');
    broadcastChannel.onmessage = (message) => {
      if (message.data === 'logout') {
        this.handleLogout();
      }
    };
  }

  get token(): string {
    return sessionStorage.getItem(this.TOKEN_NAME) || '';
  }

  get isTokenExpired(): boolean {
    return new JwtHelperService().isTokenExpired(this.token);
  }

  setToken(token: string): void {
    sessionStorage.setItem(this.TOKEN_NAME, token);
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.isTokenExpired;
  }

  async completeAuthentication(): Promise<void> {
    this.user = await this.userManager.signinRedirectCallback();
    this.setToken(this.user.access_token);
    this.router.navigate(['/dashboards']);
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect().catch((err: any) => {
      console.error('Login error:', err);
    });
  }

  handleLogout(): void {
    clearStorageByPrefix(localStorage, 'oidc.')
    this.userManager.removeUser();
    sessionStorage.removeItem(this.TOKEN_NAME);
    this.router.navigate(['/']);
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect().then(() => {
      localStorage.setItem('userLoggedOut', 'true');
      localStorage.removeItem('userLoggedOut');
      const broadcastChannel = new BroadcastChannel('auth_channel');
      broadcastChannel.postMessage('logout');
    });
  }

  async getUser(): Promise<any> {
    if (!this.user) {
      this.user = await this.userManager.getUser();
    }
    return this.user;
  }

  showLogoutMessage(showMessage: boolean): void {
    if (showMessage && this.router.url.includes("dashboards/")) {
      const message = this.translate.currentLang === "es"
        ? "¿Quieres salir del sitio web? \nEs posible que los cambios no se guarden."
        : "Do you want to leave the website? \nYour changes may not be saved.";
      
      if (confirm(message)) {
        this.logout();
      }
    }
  }

  triggerLogout(): void {
    this.logoutSubject.next();
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(`https://sportiananalysis-api-dv.mediacoach.es/userprofile`);
  }

  saveUserProfile(profile: UserProfile): Observable<any> {
    return this.http.put<any>(`https://sportiananalysis-api-dv.mediacoach.es/userprofile`, profile);
  }
}